import React from "react";
import "./HomeCard.scss";
function HomeCard() {
  return (
    <div className="card" >
      <div className="card__wrp">
        <div className="card__contents">
          <div className="container">
            <div className="card__contents__wrp">
              <div className="card__contents__lft">
                <div className="card__title__area">
                  <h2 className="card__title card__title--lft" id="about">WHY ASC?</h2>
                </div>
                <div className="card__subtitle__area card__subtile--lft">
                  <p className="card__subtile">
                  What's unique here, aside from our awesome collectible NFTs, is the support we provide for the development of Plethora, because after what's happened in our world over the past few years, it's become clear that our ways of connecting and communicating had to change. That's why we support Plethora with our NFTs.
                  </p>
                  {/* <p className="card__subtile">
                    All the information for the ASC is shared on our Discord
                    server.
                  </p> */}
                </div>
              </div>
              <div className="card__contents__rgt">
                <div className="card__title__area">
                  <h2 className="card__title card__title--rgt">IS THERE ONGOING BENEFITS??</h2>
                </div>
                <div className="card__subtitle__area card__subtile--rgt">
                  <p className="card__subtile">
                  Angry Sheep Club will always provide exclusive added and ongoing benefits for its active members and holders, like the chance to win 20 ETH in the 40 draws that follows our pre-sales and many other surprises for our active members who bought into its vision.
                  </p>
                  {/* <p className="card__subtile">
                    New way will be possible for NFT owners though.
                  </p>
                  <p className="card__subtile">
                    Some NFT are special and have a seal that allow to unlock
                    special account benefits, like a lifetime access.
                  </p>
                  <p className="card__subtile">
                    The value of these NFTs will surely skyrockets when you
                    think the subscription for Tinder Gold is at $159.99/year
                    and Tinder Platinum is at $199/year and it's just for
                    dating, if we add the business part, we can think of
                    LinkedIn Premium at $749.99/year.
                  </p>
                  <p className="card__subtile">
                    We let you do the math on how much you think a NFT with a
                    Gold (Gold), Blue (Premium) or Black (Corporate) seal that
                    give you a lifetime access can be worth.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card__contents card__contents--brd">
          <div className="container">
            <div className="card__contents__wrp">
              <div className="card__contents__lft">
                <div className="card__title__area">
                  <h2 className="card__title card__title--lft">
                    WHAT IS PLETHORA?
                  </h2>
                </div>
                <div className="card__subtitle__area card__subtile--lft">
                  <p className="card__subtile">
                  Plethora is not a game, it's an app that changes the way we connect and communicate. Plethora offers a new way to meet people whether for love or friendship, to do business and to access news that is worth paying attention to! Pretty awesome, a must have you'll see and our NFTs double as a membership card giving its holder unique perks.
                  </p>
                </div>
              </div>
              <div className="card__contents__rgt">
                <div className="card__title__area">
                  <h2 className="card__title card__title--rgt">
                  	HOW CAN I JOIN THE CLUB?                  </h2>
                </div>
                <div className="card__subtitle__area card__subtile--rgt">
                  <p className="card__subtile">
                  It's simple, join us on Discord, follow the steps, read the community and contest rules and join us in the general chat to say hello 👋 to your new family. Then, take the time to acclimate to your new environment to get the most out of it. There are many benefits waiting for you, come and get them, we have your back, just ask.
                  </p>
                  {/* <p className="card__subtile">
                    It became harder and harder to stay really connected with
                    people we love as to find people that deserve this love, to
                    find friends and it became harder to do business as well.
                  </p>
                  <p className="card__subtile">What options do we have?</p>

                  <a href="" className="card__subtile__link">
                    continue reading...
                  </a> */}
                  <div className="join-btn">
                  <a href="https://discord.gg/angrysheepclub" target="_blank"><button>Join Us</button> </a>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card__contents card__contents--brd">
          <div className="container">
            <div className="card__contents__wrp--btm">
              <div className="card__contents__lft">
                <div className="card__title__area">
                  <h2 className="card__title card__title--rgt" id="team">
                  	THE TEAM
                  </h2>
                </div>
                <div className="card__subtitle__area card__subtile--btm">
                  <p className="card__subtile">
                  The team behind Angry Sheep Club and Plethora is made up of entrepreneurs with more than 30 years of experience, experts who have carried out several complex projects, software, and applications in the financial field. The community will always have a say and influence the course of development because we believe that together we achieve more success. We are proud of our game plan with the embedded fail safe that make sure things will happen as planned so we can all win.
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeCard;
