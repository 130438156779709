import "./team.css";
import React, { useEffect } from "react";
import paco from "./../../images/paco.jpg";

import Shepherd from "./../../images/8.jpeg";

function Team() {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.charset = "utf-8";
    script.src =
      "https://www.buzzsprout.com/2151531.js?artist=&container_id=buzzsprout-large-player-limit-20&limit=20&player=large%27%3E";
    script.async = true;
    document
      .getElementById("buzzsprout-large-player-limit-10")
      .appendChild(script);

    return () => {
      // clean up the script element when the component unmounts
      document
        .getElementById("buzzsprout-large-player-limit-10")
        .removeChild(script);
    };
  }, []);

  return (
    <div className="third-section" id="team">
      <div className="team">
        <h2 className="glowing-heading">Founders</h2>
        <p>
          The founder and co-founder have both been in business for over 30
          years.
        </p>
        <div className="team-section">
          <div className="team-card">
            <img src={Shepherd} className="team-image" />
            <div className="team-info">
              <p className="team-description">
                Shepherd, founder, likes to think outside the box, professional
                coach and trainer for over 20 years, NLP and hypnosis
                specialist, research director and much more... he likes to see
                you succeed.
              </p>
              <h2>Shepherd</h2>
              <p>Founder</p>
            </div>
          </div>

          <div className="team-card">
            <img src={paco} className="team-image-paco" />
            <div className="team-info">
              <p className="team-description">
                Paco, co-founder, entrepreneur and is an advocate for creativity
                and artists. Artist himself, he won the Eurasian prize in 2018
                and has been exhibited in New York
              </p>
              <h2>Paco</h2>
              <p>Co-founder</p>
            </div>
          </div>
        </div>
        <div className="join-button">
          <a href="https://discord.gg/angrysheepclub" target="_blank">
            <button>Join Us</button>{" "}
          </a>
        </div>

        <div id="buzzsprout-large-player-limit-10"></div>
      </div>
      <div className="podcast">
        <iframe
          id="player_iframe"
          src="https://www.buzzsprout.com/2151531?artist=&client_source=large_player&iframe=true&limit=20&referrer=https%3A%2F%2Fwww.buzzsprout.com%2F2151531%2Fpodcast%2Fembed"
          loading="lazy"
          width="850"
          height="475"
          frameborder="0"
          scrolling="no"
          title="Angry Sheep Spaces"
        ></iframe>
      </div>

      <section class="curved"></section>
    </div>
  );
}
export default Team;
