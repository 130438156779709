import React from "react";
import "./Roadmap.scss";
function Roadmap() {
  return (
    <div className="roadmap" id="roadmap">
      <div className="container">
        <div className="roadmap__wrp">
          <div className="roadmap__title">
            <h1>	ANGRY SHEEP CLUB & PLETHORA ROADMAP	</h1>
          </div>
          <div className="roadmap__contents">
            <div className="roadmap__content">
              <div className="roadmap__content__card">
                <div className="roadmap__card__header">
                  <h3>ONCE UPON A TIME...</h3>
                </div>
                <div className="roadmap__card__content ">
                  <div className="roadmap__card__lft">
                    <h3>Q4</h3>
                  </div>
                  <div className="roadmap__card__rgt ">
                    <div className="roadmap__content__lft">
                      <div className="roadmap__content__lft--inr"></div>
                    </div>
                    <h3>Problems become an opportunity</h3>
                    <small>
                    No app has met our needs when it comes to connecting to find love, friends, thrive in business and get relevant news. What we tested was more like shopping, promotion, surrounded with distractions without really allowing us to really connect with people. We decided to create what we would have liked, and that's what we call Plethora.
                    </small>
                  </div>
                </div>
              </div>
              <div className="roadmap__time">
                <div className="roadmap__time__wrp">
                  <div className="roadmap__time__title">2021</div>
                </div>
              </div>
            </div>
            <div className="roadmap__content--rv ">
              <div className="roadmap__time roadmap__time__rgt">
                <div className="roadmap__time__wrp">
                  <div className="roadmap__time__title">2022</div>
                </div>
              </div>
              <div className="roadmap__content__card ">
                <div className="roadmap__card__header">
                  <h3>SPARK THE ENGINES...	</h3>
                </div>
                <div className="roadmap__card__content roadmap__card__btm">
                  <div className="roadmap__card__lft">
                    <h3>Q1</h3>
                  </div>
                  <div className="roadmap__card__rgt ">
                    <div className="roadmap__content__rgt">
                      <div className="roadmap__content__rgt--inr"></div>
                    </div>
                    <h3>The solution as an investment</h3>
                    <small className="">
                    This app has some wonderful features one of which is that we have included NFTs in it that will allow access to special accounts ensuring a solid floor value for NFT holders. It starts with the creation of the Angry Sheep Club on Discord. We do everything in our power to ensure maximum gain for our holders.
                    </small>
                  </div>
                </div>
                <div className="roadmap__card__content roadmap__card__btm">
                  <div className="roadmap__card__lft">
                    <h3>Q2</h3>
                  </div>
                  <div className="roadmap__card__rgt ">
                    <div className="roadmap__content__rgt">
                      <div className="roadmap__content__rgt--inr"></div>
                    </div>
                    <h3>Community start-up and growth Turn</h3>
                    <small className="">
                    It is the start of the Angry Sheep Club the community of which will grow organically with the help of its members. The core membership is important and the different stages act as filter so that the active members could be rewarded. This will allow the community to grow. Also, there will be big prizes at stake. 20 ETH will be given out via 40 draws to holders of the "Angry Sheep Special Edition" collection once the presales have been completed.
                    </small>
                  </div>
                </div>
                <div className="roadmap__card__content roadmap__card__btm">
                  <div className="roadmap__card__lft">
                    <h3>Q3</h3>
                  </div>
                  <div className="roadmap__card__rgt ">
                    <div className="roadmap__content__rgt">
                      <div className="roadmap__content__rgt--inr"></div>
                    </div>
                    <h3>Spark the engines!</h3>
                    <small className="">
                    There will be 4 presales for "Angry Sheep Special Edition", pre-sales will not be public and will remain exclusive to whitelisted members. Each presale will be followed by 10 draws for a total of 20 ETH which will be distributed to the winning holders. Presales allow the development of the application so we can launch its public relations and crowdfunding campaign.
                    </small>
                  </div>
                </div>
                <div className="roadmap__card__content ">
                  <div className="roadmap__card__lft">
                    <h3>Q4</h3>
                  </div>
                  <div className="roadmap__card__rgt ">
                    <div className="roadmap__content__rgt">
                      <div className="roadmap__content__rgt--inr"></div>
                    </div>
                    <h3>Angry Sheep Collection</h3>
                    <small className="">
                    The community will continue to grow. We will now be welcoming those who participated in the crowdfunding so that they too can join us to take advantage of the presale of the "Angry Sheep" collection with exclusive member benefits. This collection includes Gold, Blue and Black seals. This presale will be open for whitelisted participants only.
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="roadmap__content">
              <div className="roadmap__content__card">
                <div className="roadmap__card__header">
                  <h3>ACHIEVING THINGS...</h3>
                </div>
                <div className="roadmap__card__content roadmap__card__btm">
                  <div className="roadmap__card__lft">
                    <h3>Q4</h3>
                  </div>
                  <div className="roadmap__card__rgt ">
                    <div className="roadmap__content__lft">
                      <div className="roadmap__content__lft--inr"></div>
                    </div>
                    <h3>Alpha launch</h3>
                    <small>
                      Alpha access level individuals can access the Alpha
                      version and influence PLETHORA development.
                    </small>
                  </div>
                </div>

                <div className="roadmap__card__content roadmap__card__btm">
                  <div className="roadmap__card__lft">
                    <h3>Q2</h3>
                  </div>
                  <div className="roadmap__card__rgt ">
                    <div className="roadmap__content__lft">
                      <div className="roadmap__content__lft--inr"></div>
                    </div>
                    <h3>Angry Sheep</h3>
                    <small>Sales of the Angry Sheep collection continues</small>
                  </div>
                </div>
                <div className="roadmap__card__content roadmap__card__btm">
                  <div className="roadmap__card__lft">
                    <h3>Q3</h3>
                  </div>
                  <div className="roadmap__card__rgt ">
                    <div className="roadmap__content__lft">
                      <div className="roadmap__content__lft--inr"></div>
                    </div>
                    <h3>Beta launch</h3>
                    <small>
                    12	Q3	Beta launch	Beta access level individuals can access the Beta version and influence the final version of PLETHORA.
                    </small>
                  </div>
                </div>
                <div className="roadmap__card__content ">
                  <div className="roadmap__card__lft">
                    <h3>Q4</h3>
                  </div>
                  <div className="roadmap__card__rgt ">
                    <div className="roadmap__content__lft">
                      <div className="roadmap__content__lft--inr"></div>
                    </div>
                    <h3>LIVE Version released</h3>
                    <small>
                    LIVE Version released	It's the big day, the LIVE version launch. Celebration! Surprises! 
                    </small>
                  </div>
                </div>
              </div>
              <div className="roadmap__time">
                <div className="roadmap__time__wrp">
                  <div className="roadmap__time__title">2023</div>
                </div>
              </div>
            </div>
            <div className="roadmap__content--rv ">
              <div className="roadmap__time roadmap__time__rgt">
                <div className="roadmap__time__wrp">
                  <div className="roadmap__time__title">2024</div>
                </div>
              </div>
              <div className="roadmap__content__card ">
                <div className="roadmap__card__header">
                  <h3>CELEBRATING AND CONTRIBUTING...</h3>
                </div>
                <div className="roadmap__card__content ">
                  <div className="roadmap__card__lft">
                    <h3>Q1</h3>
                  </div>
                  <div className="roadmap__card__rgt ">
                    <div className="roadmap__content__rgt">
                      <div className="roadmap__content__rgt--inr"></div>
                    </div>
                    <h3>Way more to come...</h3>
                    <small className="">
                      The success story continues, in the Metaverse as well...
                      Stay tuned!
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
