export const PlatinumSeriesAddresses = [
  "0x1140EDCaaAf497b15966e0D1f764aEf3A5cfBA3E",
  "0xC967bF6f3B2fd4dCd3A9a5c443CD26974CBa6F14",
  "0x32f986a8De00d6d4696d0B44D692715FB1F98712",
  "0x9D943BCA05084c685C8467E3979bbC481Ff5E72b",
  "0x64a4042EfFd8ECa6b8c8f25ECB61B402697D7a10",
  "0x37fB5719cb1E022cB7b24409F2002716e024f3f7",
  "0xf301A1aE0ECB41D13E477f7E4cb0EF3FaDbF9ee5",
  "0xB37985Aed341D754b8D894D9d2a18f18D52B03d7",
  "0x44EFEaf57E81dEf23F4093C24E0DBdc27377E47d",
  "0x4E71446A34BDb09AA38E84F174FE4AcD7EB70CC6",
  "0x96d38f3ddf269ba5812b719c2591e8f0f7c3b27b",
  "0x659014846475B894D2038CC0bfDf53341C44d123",
  "0x459946Da75ad7D8FE6c8dD96c76883C50C334793",
  "0x15379191a417c3A0578a8a604E56764d3fb93b8f",
  "0xd2A759020d854fA13D71a0027e9486f77535567B",
  "0xE8e8A647e2EE752417a4684A4fB2DB55D2264A81",
  "0xd2A759020d854fA13D71a0027e9486f77535567B",
  "0xd2A759020d854fA13D71a0027e9486f77535567B",
  "0xdd43ddf301b258aEAc6292d0a0f3E8d993Fb15C3",
  "0x7b358A3A24b8609c3f5e663Bb57AAB1D7e3E30C7",
  "0x4686a2954008263995D8E28b6d1dDA82715F0C05",
  "0x5ea4A35950eC9DFf0000443d0116F174DDC1BC2B",
  "0x37Ee2150b264b43aa0be8fC265B55a4d4c9348D5",
  "0xB677a60C70e94f919084a902604378217e6FbeF7",
  "0x47721F0e17709CCd6C39bA1EFab443fA10E14df5",
  "0x3C3f823EaB7B076454c9822AE914ddA1aa50bD08",
  "0xaa17c3F181d2A6AaEb0E5B3C5d08123FC9A30aBa",
  "0xc5f87F0eD0acf9B030bE8C313EB0f135E76BEC86",
  "0x794133661621323aFf9BE74BE47495131A39deA7",
  "0xDF7b774295C12ad8a7A4815D4AaB04353d3Dd201",
  "0x895264cAf91e273Faa576893D40993d2a911CBd9",
  "0x25D8Ea15eeAEFc8f89d791c08885bbAedE61bEAe",
  "0xC95306F4e1187242B5C51B28B9EdC8B467f23f39",
  "0x48D4E8Bfb428cc5A6Ade1E9Fc6677Cfa167A65f7",
  "0xC3B03b0ab7187171AA31FE2115d3e637c081A4f1",
  "0x0Dd409C52DCed953354795d7C56AAD3BC2437755",
  "0x783433375a9829B647E28fe82F81A20989cDc8b9",
  "0x8f939e785e6be9d14AB68C5f56A72F3Bb1fE30C7",
  "0x4686a2954008263995D8E28b6d1dDA82715F0C05",
  "0x99b27EcFe916D95FcdE165b4C7c5f98cc797F795",
  "0x25BeE3a7F5FC11baf72989C8077DAafc05Dd2AbA",
  "0xc87B8d82fD514BF21dec7b0B8A72C1B206f1a076",
  "0x25907BE2D625EaBFC4b4E83E17dCABE51959e35B",
  "0xd41A0B4E72408b97721cE5cAa519Ed57E0aef49a",
  "0x63Af19cD3E5A8114e32B478190C7075f637ea378",
  "0x8f939e785e6be9d14AB68C5f56A72F3Bb1fE30C7",
  "0x902D03D5Be8FfA924e3357539B96Cc75424aeFd9",
  "0x8732fC420498CAA9ddB19e8F1Fca6f2bB81b1040",
  "0xd9E52dcc6e30682e2BAa5937bfFaEF62EBCf17C5",
  "0xB26E01491888A3356A2e2E45a9e0a9963aBe724c",
  "0x9D943BCA05084c685C8467E3979bbC481Ff5E72b",
  "0xF95272443c81568688b19743380b985dd9A82D31",
  "0x0e4B5619ea45b8BCF312F6BFB715caA265323F2e",
  "0x58BDE2822349D2F7B819a6978D03bdaF78A1fD13",
  "0xa32E855B8726AA507Fb2D8dAB60112d799e2126f",
  "0xa093b6860d24DF5853fe9721A461acc02c49ffbc",
  "0xAf0c20194f664d8D0193565a7B550ab062D07247",
  "0x6Fd69073f458Ed3566DDd7f9F37409994bA59A78",
  "0x5b13d359f6783e0faFbb4Bc53b55306bcB6B02df",
  "0x78094f20b3b60eCD786b21812173c2B9E4BCA2C5",
  "0x391E7d3be8806fe14a787666eDf4619e94B49625",
  "0x0E5F2339803Dd4407e5622e2b3df5410EB5AA3bb",
  "0x0E48b4C20Aba46025F91387d637680af3df7a576",
  "0xA59B2aFffcf247b5Ec1aeB7f4FE9FF37AaE0CAF3",
  "0x24A4874c32bC405d0d691E969C66AaF1D96cE6Db",
  "0x4268B6dbA50992f32Ae0D2d47ACc1Be5525D3f35",
  "0x37c4220bCFc711cCACCA603Bc1BB2705d70836C3",
  "0xE532a6907e46c61CFC7B30CC2881E5819384E9E5",
  "0x188edb0948048dfC667762A06e73fc41a01A6fB0",
  "0x58Cd176eF0854c7a59EADcee32c63ed9ca9D4cca",
  "0x89f6c3a100Ad830E910caBe13bed80231928F6C6",
  "0x90Eb1303e87E824dB55Fd53e00816796D281A51D",
  "0x1D3fCBd4271f8d6696a3D214c326cF48876d27e5",
  "0x0778Cc202f530EEe36802d6aB7E01b535067487a",
  "0x8A536E3b859938d8749365A7E4352b4B1AF9B47f",
  "0xE2b132ddF2950ec385df978736d3450690c8520A",
  "0xFf25Ca33142d0302C4Bd950325B73C21c7EfD756",
  "0xE3CB206b545b79dBd38344f933134dae265F264E",
  "0x3CAF100c0B7A4674dEe4c80CDa8c037f07148951",
  "0xB8617CD9AAb202d2d58C97421b9bba33B9c52b28",
  "0x33C6b84f678E50DBe2e68ccd7CBC13607FF67C38",
  "0x181bCcCa24ff1b133dCeb3a8A820fE92bB320978",
  "0xeadA09D9800B99A56608CBBef6bDAF0caDB330f1",
  "0xFF122c6cc8ee72D0dFff01dE0a1A29485fee5381",
  "0xb5c2dDfD5E0F2cC76964CFEbf2ac5a7F6bA86BF0",
  "0xD44363D70b5CE5995319c9933E43beb9825d724E",
  "0x969F9DDDFA33b61d7Bd506111cEA6BEF3f5B173e",
  "0x91413798219f2D288Ad9281849daD4D75F7aBc86",
  "0x0381B4Ab84dD8E76e2807C1ea23725CA338F1b8D",
  "0x46b20Ce777f2d6D00E84c6986BF228bdD668F132",
  "0x178e12E43f6F60F99311c6f6581ec9AD0439eF5f",
  "0xc603d9fa6C246f72Dcb9Bb6b16fCF116aa0ca338",
  "0x7De8B9905d584B264602ab8B548ccc362BEf9F82",
  "0xC78671Ffc2a0D1779AEe61E7177901FAE51F6A7E",
  "0x1F4035e9A1d6Ce62b1eC092234647e1A86141501",
  "0xFCcf30208a82919bfB3b05D68C671d93794242c6",
  "0x7a634184b66F1e1FC0E11E6FA80B87718beDB326",
  "0xc7015ecC5AedC532ab5b4541F5a5C64690F82621",
  "0x32A1Eb1CE2b28331FB2aD4E117f03378B509145E",
  "0xd4D3Dc5dfe9018C8B96fD9e3acC1F1BebD99ad3e",
  "0x171Bb6F574c210F141284412723e09dF9e90d401",
  "0x3ee1aFCc29C6904af97Ec428B838508B317989a9",
  "0xBDE03408dEF52f9a0470d2EA5D617593741f569D",
  "0x8f9679E8b24F17195369fFfFb8aF23f7da5f72F2",
  "0x14a6e823Fc11b629ac8b798f378A77d31dF8537E",
  "0x739a31FDC83C777Cb9CfB1E9605a1f0f14327518",
  "0x9acF346D9F1Ff2b6398fd23e4b933DddB430A8a9",
  "0x39fe36cAfa28d84455f1A263621e95F91139F884",
  "0x9C76d259AF427E385bBE2Efc37BfA1ffd28d2de4",
  "0x9379d98D77Df9f3d9e1dEcBDcC04c27Aa40D99a3",
  "0xdE68Cdc05bCBC4f50f28e762354Db52Bbd6510Ff",
  "0x792a8ba3e322313a09bfe3dc97b16daa90980050",
  "0xF7cA6595A5Cd1011b455eb54d82a5Ca64b43015a",
  "0x610D5267C83520cBE2491d822eB63bD16Cb7d296",
  "0xE64b6d53b27E2979678A154FB9409D884da60395",
  "0x1eEcD3E49D47807183329263A871BDC105A5807b",
  "0x7444087b662817A1E958aE7EB36D2bd6d22f40A8",
  "0x760dBFD9FEe04FBc23dCAB09EeAcC3C874E90efc",
  "0xF6C153e5278958236E50A1d7B8C764B46960B296",
  "0xC592F12073CC89df70250df850c74Aa2D0C69943",
  "0xFA1e3b383Ce51310492056A038770cac39506124",
  "0x1fC78267060E5f28F6d9a4b336588a7b9213d959",
  "0x09C242Dc92b5bB5B17E6975818B6dfa5AdDC632a",
  "0xe336c6763E06063D3D11229e99b46e301Cf4B3be",
  "0xB52F6b4694d561a44baE54Fc2fcDcf0891c6D057",
  "0x585Cc24EE9f9E8AFec13A10ec10FBc709E19328F",
  "0x6A329d74b030d0c4DD1669EAFeAeeCC534803E40",
  "0x392CA073278052A1D32C6b389218fbf257aC823b",
  "0x76a2473E0dD655b7100E8C639aF52A6f5BaF0A03",
  "0xF188bd32B9AF7923Db14f4ff99cd78bB13B4fe97",
  "0x8B0de06Cca01fD11980718d167f848C333278bf5",
  "0x0d993fb9CB451Ee561A70849Ed28e49fb784e571",
  "0xA3B6CeFc43e6dCA3F4380D5a5C5DcE7071ED2bd6",
  "0x1eEcD3E49D47807183329263A871BDC105A5807b",
  "0x55dCF6754bbdCc893909F9524c1D25B7cbb0d2Df",
  "0x3baD58514a98FCdbA7ac0B010a0554E3d724f031",
  "0x062f0c85e0e5902a3917391EbA323769660a4849",
  "0xD34604aCc4e6cA8F99287251B7b6f78BF9BFEEc5",
  "0x91b14234fc9AB21261c02A5D39C50074cEb66bfC",
  "0x115E476Dd94aFBD5E10f48Fcf735dcdAa4bDb8B3",
  "0xC90567B9a915911B65724EDAB34b2BFCa335c18e",
  "0x134855a52C0668a51595C13F3049617B278f9Fb6",
  "0x5597D5ecE6Fd9E44595cE2a126cDe4cC6b3C624B",
  "0x81EC8351740F21e798E40b853A887a10bEfA96c0",
  "0xE2F6d617E406e939914Fac88e6f7708174B9306b",
  "0x72c6857E76FB641B7AAA8e4503c146dfa5FD6Ceb",
  "0x7888C7e39A5Ea89712fBD4944D1dd06621e51b89",
];
