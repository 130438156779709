import React from "react";
import "./Footer.scss";
import discord from "./../../images/discord.png";
import twitter1 from "./../../images/twitter1.png";
import telegram from "./../../images/telegram.png";

import instadark from "./../../images/instagram dark.png";
import nftschedule from "./../../images/nftschedule-logo.png";
function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="faq-content">
          <h2 className="faq-bigtext">ANGRY SHEEP CLUB</h2>
          <p className="faq-smalltext">
            Angry Sheep Club, Dubai <br />
          </p>
          <p className="faq-smalltext">
            Supported by the NonFungibleCompany.Co and You Said App Co.
          </p>
        </div>
      </div>
      {/* <div className="nftschedulesection">
        <h2 className="faq-bigtext"> As seen on</h2>
        <a href="https://nftschedule.online/" target="_blank">
          <img className="nftscheduleimg" src={nftschedule} />
        </a>
      </div> */}
      <div className="social">
        <a
          href="https://discord.com/servers/angry-sheep-club-936248417557033020"
          target="_blank"
        >
          <img src={discord}></img>
        </a>
        <a href="https://twitter.com/AngrySheepClub" target="_blank">
          <img src={twitter1}></img>
        </a>
        <a href="https://t.me/AngrySheepClub" target="_blank">
          <img src={telegram}></img>
        </a>
      </div>
      <p className="faq-smallertext">
        © 2022 <span className="yellow-text">ANGRY SHEEP CLUB</span> ALL RIGHTS
        RESERVED <br />
        NonFungibleCompany.Co L.L.C-FZ
      </p>
    </div>
  );
}

export default Footer;
