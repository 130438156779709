import React from "react";

import ReactPlayer from "react-player/lazy";
import "./initiative.css";
import glowinglogo from "./../../images/newlogo.png";
import dots from "./../../images/Asset 2.png";
import cube from "./../../images/Asset 3.png";
import cubes from "./../../images/Asset 4.png";
import video from "./../../images/video.mov";

const iframeSource =
  ' <iframe src="https://player.vimeo.com/video/750444570?h=ae07b3d8c7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen  style="width:100%;height:100%;" title="Angry Sheep Club"> </iframe>';
function Initiative() {
  return (
    <div className="second-section" id="initiative">
      <div className="container">
        <div className="initiative">
          <div className="left-column">
            <img src={glowinglogo} className="glowinglogo"></img>
          </div>
          <div className="right-column">
            <h2 className="glowing-heading">THE INITIATIVE</h2>
            <p className="text">
              We are a private membership club that is filled with real,
              motivated and like-minded people. Everyone of them is on a mission
              to generate wealth and happiness for themselves, and refuses the
              status quo. It’s the essence of the Angry Sheep Character itself:
              it says enough is enough, and has gone out to do things its own
              way. <br /> <br />
              We are a community of <b>NFT enthusiasts</b> focused on creating
              <b> tangible benefits in real life.</b> Growing organically, we
              provide 24/7 support through our well-trained Club Guardians.{" "}
              <br />
            </p>
          </div>
        </div>
        <div className="initiative2">
          <div className="left-column2">
            <h2 className="glowing-heading">ONGOING BENEFITS</h2>
            <p className="text">
              There are out-of-this-world benefits for{" "}
              <b>
                {" "}
                challenge and prize seekers, NFT enthusiasts, entrepreneurs{" "}
              </b>{" "}
              (or aspiring to become one), Diamond Hands HODLer, and personal
              and professional growth fans. <br />
              <br />
              Come check it out for yourself!
              <br />
              <br />
            </p>
            <div className="join-button">
              <a href="https://discord.gg/angrysheepclub" target="_blank">
                <button>Join Us</button>{" "}
              </a>
            </div>
          </div>
          <div className="right-column2">
            <img src={dots} className="glowinglogo"></img>
          </div>
        </div>
        <div className="initiative">
          <div className="left-column">
            <img src={cube} className="glowinglogo"></img>
          </div>
          <div className="right-column">
            <h2 className="glowing-heading">AWESOME COLLABORATIONS</h2>
            <p className="text">
              Everything is really aligned to benefit everyone at the same time.{" "}
              <br /> <br />
              We are <b> always open </b> to discussion and <b>always ready</b>{" "}
              to innovate and
              <b> take things to the next level </b> and have fun doing it.
              <br /> <br />
              No competition here, but collaboration and support.{" "}
              <b>No one loses, everyone wins! </b>
              <br /> <br />
              We have lots of ideas for the future, but we'll keep that for our
              partners.
            </p>
          </div>
        </div>
        {/* <div className="initiative2">
          <div className="left-column2">
            <h2 className="glowing-heading">THE CLUB LEAD BY EXAMPLE </h2>
            <p className="text">
              Awesome prizes are given. The prizes and giveaways the Club has
              announced are all going to be distributed by NonFungibleCompany.Co
              from the profits generated from minting at each Presale; it’s not
              ETH being thrown around to entice people to mint in the hopes of
              turning a quick profit.
              <br /> <br />
              The project feeds into itself: the more members put in, the more
              they get out of it. It’s sustainable, capable of growth, and
              poised to become the star of the NFT community. See you inside.
              <br />
              <br />
              See you inside.
            </p>
            <div className="join-button">
              <a href="https://discord.gg/angrysheepclub" target="_blank">
                <button>Join Us</button>{" "}
              </a>
            </div>
          </div>
          <div className="right-column2">
            {/* <img src={cubes} className="glowinglogo"></img> 
            {/* <div
              style={{ height: "20rem", width: "80%" }}
              dangerouslySetInnerHTML={{ __html: iframeSource }}
            ></div> 
          </div>
        </div> */}
      </div>
    </div>
  );
}
export default Initiative;
