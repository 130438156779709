import "./faq.css";
import "./Accordion.css";
import AccordionWrapper from "./AccordionWrapper";
import AccordionItem from "./AccordionItem";
function FAQ() {
  return (
    <div className="faqsection" id="faq">
      <div className="container">
        <div className="faqbox">
          <h2 className="faq-heading">FREQUENTLY ASKED QUESTIONS</h2>
          <div className="faq-content">
            <AccordionWrapper>
              <AccordionItem
                index={0}
                title="What is Angry Sheep Club?"
                description={
                  "We are a private membership club and we’re on a mission to generate wealth and happiness while contributing to something greater than ourselves."
                }
              />

              <AccordionItem
                index={1}
                title="What exactly does the Club do?"
                description={
                  "We’ve created this initiative so we can all access incredible opportunities to truly generate wealth and happiness, while contributing to something greater. We are very proud of this first initiative, made possible by the collaboration of certain affiliate members, benefactors, philanthropists and many others that will join us along the way. This forms a generative ecosystem allowing us to access something unique that has never been done before."
                }
              />

              <AccordionItem
                index={2}
                title="Are there prizes to be won?"
                description="Yes, through the Awareness Marathon there are 50 ETH up for grabs, which will make 120 winners. "
              />

              <AccordionItem
                index={3}
                title="How can I win"
                description={
                  "You must join the Club, subscribe to the Awareness Marathon and run for it."
                }
              />
              <AccordionItem
                index={4}
                title="Does it cost anything to join the Club?"
                description={
                  "No, Club membership is free to join. It is by being an active member, through your participation in the Awareness Marathon, help others so they can benefit from what the Club has to offer too."
                }
              />
            </AccordionWrapper>
            <div className="faq-button">
              <a href="https://discord.gg/angrysheepclub" target="_blank">
                <button>Join Us</button>{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
