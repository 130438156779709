import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./blueprint.css";
import bpleft from "./../../images/Asset 12.png";
import bpright from "./../../images/Asset 8.png";
import bptopleft from "./../../images/Asset 14.png";
import bptopright from "./../../images/Asset 11.png";
import bpbottomright from "./../../images/Asset 15.png";
import bpbottomleft from "./../../images/Asset 13.png";
import center from "./../../images/Asset 9.png";
import bpleftmobile from "./../../images/APM.png";
import bprightmobile from "./../../images/WEB3.png";
import bptopleftmobile from "./../../images/HOLDERS-ZONE.png";
import bptoprightmobile from "./../../images/MASTER-MIND.png";
import bpbottomrightmobile from "./../../images/APP.png";
import bpbottomleftmobile from "./../../images/NFT.png";
import centermobile from "./../../images/Asset 9.png";
import xicon from "./../../images/x-icon.png";

import Modal from "react-modal";
Modal.setAppElement("body");
function Blueprint() {
  const [isLeftModalOpen, setLeftIsOpen] = React.useState(false);
  const [isRightModalOpen, setRightIsOpen] = React.useState(false);
  const [isLeftTopModalOpen, setLeftTopIsOpen] = React.useState(false);
  const [isRightTopModalOpen, setRightTopIsOpen] = React.useState(false);
  const [isLeftBottomModalOpen, setLeftBottomIsOpen] = React.useState(false);
  const [isRightBottomModalOpen, setRightBottomIsOpen] = React.useState(false);
  const [isCenterModalOpen, setCenterIsOpen] = React.useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  function openLeftModal() {
    setLeftIsOpen(true);
    setModalOpen(true);
    console.log("section clicked and modal opened");
  }
  function closeLeftModal() {
    setLeftIsOpen(false);
    setModalOpen(false);
  }

  function openRightModal() {
    setRightIsOpen(true);
    setModalOpen(true);
    console.log("section clicked and modal opened");
  }
  function closeRightModal() {
    setRightIsOpen(false);
    setModalOpen(false);
  }

  function openLeftTopModal() {
    setLeftTopIsOpen(true);
    setModalOpen(true);
    console.log("section clicked and modal opened");
  }
  function closeLeftTopModal() {
    setLeftTopIsOpen(false);
    setModalOpen(false);
  }

  function openRightTopModal() {
    setRightTopIsOpen(true);
    setModalOpen(true);
    console.log("section clicked and modal opened");
  }
  function closeRightTopModal() {
    setRightTopIsOpen(false);
    setModalOpen(false);
  }

  function openLeftBottomModal() {
    setLeftBottomIsOpen(true);
    setModalOpen(true);
    console.log("section clicked and modal opened");
  }
  function closeLeftBottomModal() {
    setLeftBottomIsOpen(false);
    setModalOpen(false);
  }

  function openRightBottomModal() {
    setRightBottomIsOpen(true);
    setModalOpen(true);
    console.log("section clicked and modal opened");
  }
  function closeRightBottomModal() {
    setRightBottomIsOpen(false);
    setModalOpen(false);
  }

  function openCenterModal() {
    setCenterIsOpen(true);
    setModalOpen(true);
    console.log("section clicked and modal opened");
  }
  function closeCenterModal() {
    setCenterIsOpen(false);
    setModalOpen(false);
  }

  React.useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible ";
    }
  }, [isModalOpen]);
  return (
    <div className="blueprint-section" id="blueprint">
      <h2 className="border-heading">ANGRY SHEEP CLUB INITIATIVE BLUEPRINT</h2>
      <div className="blueprint">
        <img src={bpleft} className="bp-longsection" onClick={openLeftModal} />

        <div className="middle-bp">
          <div className="left-top-corner">
            <img src={bptopleft} onClick={openLeftTopModal} />
          </div>

          <div className="right-top-corner">
            <img src={bptopright} onClick={openRightTopModal} />
          </div>
          <div className="left-bottom-corner" onClick={openLeftBottomModal}>
            <img src={bpbottomleft} />{" "}
          </div>
          <div className="right-bottom-corner" onClick={openRightBottomModal}>
            <img src={bpbottomright} />
          </div>
          <div className="center-image" onClick={openCenterModal}>
            <img src={center} />
          </div>
        </div>
        <img
          src={bpright}
          className="bp-longsection"
          onClick={openRightModal}
        />
      </div>
      <div className="blueprint-mobile">
        <div className="middle-bp">
          <div className="left-top-corner">
            <img src={bptopleftmobile} onClick={openLeftTopModal} />
          </div>

          <div className="right-top-corner">
            <img src={bptoprightmobile} onClick={openRightTopModal} />
          </div>
          <div className="left-bottom-corner">
            <img src={bpbottomleftmobile} onClick={openLeftBottomModal} />{" "}
          </div>
          <div className="right-bottom-corner">
            <img src={bpbottomrightmobile} onClick={openRightBottomModal} />
          </div>
          <div className="center-image">
            <img src={centermobile} onClick={openCenterModal} />
          </div>
        </div>

        <div className="mobile-row">
          <img
            src={bpleftmobile}
            className="bp-longsection"
            onClick={openLeftModal}
          />

          <img
            src={bprightmobile}
            className="bp-longsection"
            onClick={openRightModal}
          />
        </div>
      </div>
      <p className="text bp ">
        This is more than just a roadmap: it’s a whole generative ecosystem we
        created through the Club initiative, which allows members to generate
        wealth and happiness while contributing to something greater than
        themselves
      </p>
      <Modal
        isOpen={isLeftModalOpen}
        onRequestClose={closeLeftModal}
        className="Modal"
        contentLabel="Example Modal"
      >
        <img src={xicon} className="close-button" onClick={closeLeftModal} />
        <div className="modal-content">
          <div className="modal-left">
            <img src={bpleft} className="bpleft-image" />
          </div>
          <div className="modal-right">
            <h2 className="modal-heading">AWARENESS PROGRAM MARATHON</h2>
            <p className="modal-text">
              <b>What is the goal of the Awareness Program?</b> <br />
              The primary goal of the Awareness Program is to provide Club
              members with a fun way to help its growth by spreading its
              existence on social media. <br />
              The Awareness Program allows you to contribute to the greater good
              of the Club and earn points in return for your contribution.{" "}
              <br />
              This way, it's easy for you and everyone else to see your level of
              involvement with the Club, while making you eligible to whitelist,
              and get access to all perks and benefits that come with being a
              Holder member once you’ve minted. <br /> <br />
              <b>What is the Awareness Program Marathon?</b> <br />
              The Marathon is what we've organized to support the launch of the
              Angry Sheep Platinum Series NFT collection, which will be released
              via 5 exclusive Presales to whitelisted members only. <br />
            </p>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isRightModalOpen}
        onRequestClose={closeRightModal}
        className="Modal"
        contentLabel="Example Modal"
      >
        <img src={xicon} className="close-button" onClick={closeRightModal} />
        <div className="modal-content">
          <div className="modal-left">
            <img src={bpright} className="bpleft-image" />
          </div>
          <div className="modal-right">
            <h2 className="modal-heading">WEB3</h2>
            <p className="modal-text">
              It's Madness in here. Anything is possible at this point! Along
              with everything our Holder members will bring to the table, we
              have some crazy ideas: think great TV shows, games, merch and a
              real estate project that will blow your mind. <br /> <br />
              What are we going to do exactly? When will this happen? How will
              it happen? Stick around to find out. it'll be worth it, we
              promise.
            </p>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isLeftTopModalOpen}
        onRequestClose={closeLeftTopModal}
        className="Modal"
        contentLabel="Example Modal"
      >
        <img src={xicon} className="close-button" onClick={closeLeftTopModal} />
        <div className="modal-content">
          <div className="modal-left">
            <img src={bptopleft} />
          </div>
          <div className="modal-right">
            <h2 className="modal-heading">HOLDER ZONE</h2>
            <p className="modal-text">
              When you become a Holder member, you’ll have access to the HOLDER
              ZONE and this is where the magic happens. You get direct access to
              the Club’s founder, to the Mastermind sessions, to the Club’s
              Think Tank where members can influence the development of the
              Plethora.life app and everything to follow in Web3, and get the
              commercialization rights to the NFTs you minted. This zone will
              give you the support, connections and opportunities you need to
              grow—as a person, as an entrepreneur, and as a member.
              <br /> <br />
              <b> And there is more.</b> As a Holder member, you can have access
              to the Platinum Holder Members Pool and when a fellow member or a
              benefactor gives back to this Pool, like some of our affiliates
              pledge to do, then you as a Holder member can claim your fair
              share. More to come.
              <br /> <br />
            </p>
          </div>
          <p className="modal-text">
            If you’re one of the first members to mint an Angry Sheep Club
            Platinum Series NFT in the first Presale, you are eligible to
            register to the exclusive Platinum OG Minters Pool reserved for OG
            Minters. In addition to any act of philanthropy that can come in, 5%
            of all resale goes into this Pool. If you sell the Angry Sheep NFT,
            you can’t claim anything anymore—so get in now. More to come.
          </p>
        </div>
      </Modal>

      <Modal
        isOpen={isRightTopModalOpen}
        onRequestClose={closeRightTopModal}
        className="Modal"
        contentLabel="Example Modal"
      >
        <img
          src={xicon}
          className="close-button"
          onClick={closeRightTopModal}
        />
        <div className="modal-content">
          <div className="modal-left">
            <img src={bptopright} />
          </div>
          <div className="modal-right">
            <h2 className="modal-heading">MASTERMIND</h2>
            <p className="modal-text">
              As a Holder member, you unlock the Holder Zone, and this is where
              the magic happens and our Mastermind sessions will be given to
              give you the support, connections and opportunities you need to
              grow—as a person, as an entrepreneur.
              <br /> <br />
              These Mastermind sessions will aim to help you achieve your goal
              as an entrepreneur and generate your own wealth using the
              Plethora.life app. You are not yet an entrepreneur or do not know
              where to start to become one? No problem, because we'll help you
              become one so you can realize your full potential.
            </p>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isLeftBottomModalOpen}
        onRequestClose={closeLeftBottomModal}
        className="Modal"
        contentLabel="Example Modal"
      >
        <img
          src={xicon}
          className="close-button"
          onClick={closeLeftBottomModal}
        />
        <div className="modal-content">
          <div className="modal-left">
            <img src={bpbottomleft} />
          </div>
          <div className="modal-right">
            <h2 className="modal-heading">NFT</h2>
            <p className="modal-text">
              The Angry Sheep Club Platinum Series NFTs is a collection of
              15,001 collectibles, to be minted across 5 distinct private
              Presales. But Holder members don’t buy these NFTs to sell them
              after. <br />
              <b>Why? </b> Because when you become a Holder member you unlock
              the Holder Zone, and this is where the magic happens. You get
              direct access to the Club’s founder, to the Mastermind sessions,
              to the Club’s Think Tank where members can influence the
              development of the Plethora.life app and everything to follow in
              Web3, and get the commercialization rights to the NFTs you minted.
              This zone will give you the support, connections and opportunities
              you need to grow—as a person, as an entrepreneur, and as a member.
            </p>
            <p className="modal-text">
              All our NFTs are awesome collectibles that offer special benefits
              to our Holder members within the Club, these benefits are not
              available to public holders
            </p>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isRightBottomModalOpen}
        onRequestClose={closeRightBottomModal}
        className="Modal"
        contentLabel="Example Modal"
      >
        <img
          src={xicon}
          className="close-button"
          onClick={closeRightBottomModal}
        />
        <div className="modal-content">
          <div className="modal-left">
            <img src={bpbottomright} />
          </div>
          <div className="modal-right">
            <h2 className="modal-heading">APP</h2>
            <p className="modal-text">
              We are proud to be the first to have access to the Plethora.life
              app. Because You Said App Co works behind the scenes of the
              Plethora.life app, and Angry Sheep Club Holder members will have
              lifetime exclusive access to everything in it as soon as it hits
              beta testing and for every phase to launch and beyond.
              <br /> <br />
              Plethora.life will change the way we interact and form
              relationships: in life, love and business. The app launch is
              powered by Club NFT sales. After each presale ends, a portion of
              the proceeds are used to launch Plethora.life.
              <br /> <br />
              As Holder members, we have access to the club's think tank where
              we can and will influence the development of the Plethora.life
              app. The You Said App team will work closely with us to do
              everything in their power to make it as easy as possible for us to
              generate wealth using this amazing tool in the making.
            </p>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isCenterModalOpen}
        onRequestClose={closeCenterModal}
        className="Modal"
        contentLabel="Example Modal"
      >
        <img src={xicon} className="close-button" onClick={closeCenterModal} />
        <div className="modal-content">
          <div className="modal-left">
            <img src={center} />
          </div>
          <div className="modal-right">
            <h2 className="modal-heading">THE CLUB</h2>
            <p className="modal-text">
              Angry Sheep Club is a private membership club that is filled with
              real, motivated and like-minded people. <br /> <br />
              Everyone of them is on a mission to generate wealth and happiness
              for themselves, and refuses the status quo. It’s the essence of
              the angry sheep character itself: it says enough is enough, and
              has gone out to do things its own way. <br /> <br />
              The idea behind this community is to be a support system for
              others in their mission of generating abundance in all aspects of
              their lives. <br /> <br />
              Where great people come together to generate wealth and happiness
              while contributing to something greater.
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default Blueprint;
