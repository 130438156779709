export const first100Addresses = [
  {
    address: "0x2b838Ed253f393466D1330e34Fcb78e4F77d12a8",
    serialNumber: "cWFPVRoeLN7jo4rqk5tZ",
  },
  {
    address: "0x0A9dF9614f57183829dF02DbFc03147Daf2C5759",
    serialNumber: "Epy7GqTtDm3jpKfxbWcs",
  },
  {
    address: "0x134855a52C0668a51595C13F3049617B278f9Fb6",
    serialNumber: "qiSmADj6L6mZymwzJJWk",
  },
  {
    address: "0x0A9dF9614f57183829dF02DbFc03147Daf2C5759",
    serialNumber: "KR4EtbhJoiPGQSCNQtSL",
  },
  {
    address: "0x2b838Ed253f393466D1330e34Fcb78e4F77d12a8",
    serialNumber: "NBpPggiqGEjZwivrvS9P",
  },
];
