import React from "react";
import { Link } from "react-router-dom";
import close from "./../../images/close.svg";
import "./sidebar.scss";

function Sidebar({ setIsClose }) {
  return (
    <div className="sidebar">
      {/* <button onClick={() => setIsClose(false)} className="sidebar__close--btn">
        <img src={close} alt="" />
      </button> */}
      <div className="sidebar__wrapped">
        <ul className="sidebar__links">
          <li className="sidebar__link">
            <a href="/">Home</a>
          </li>
          <li className="sidebar__link">
            <a href="#initiative">Initiative</a>
          </li>
          <li className="sidebar__link">
            <a href="#team">Team</a>
          </li>
          <li className="sidebar__link">
            <a href="#blueprint">Blueprint</a>
          </li>
          <li className="sidebar__link">
            <a href="#faq">FAQ</a>
          </li>
        </ul>
        <div className="sidebar__scl--icons"></div>
      </div>
    </div>
  );
}

export default Sidebar;
