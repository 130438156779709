import "./App.scss";
import Footer from "./components/Footer/Footer";
import HomeCard from "./components/HomeCard/HomeCard";
import FAQ from "./components/faq/faq";
import Initiative from "./components/initiative/initiative";
import Team from "./components/team/team";
import Blueprint from "./components/blueprint/blueprint";
import NavBar from "./components/NavBar/NavBar";
import Roadmap from "./components/Roadmap/Roadmap";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import heroimage from "./images/angrysheep.png";
import twitter from "./images/twitter.png";
import instalight from "./images/instagram light.png";
import AP from "./images/ap.png";
import benzinga from "./images/benzinga.png";
import digitaljournal from "./images/digitaljournal.jpg";
import marketwatch from "./images/marketwatch.png";
import yahoo from "./images/yahoo.png";
import yahoonews from "./images/yahoonews.png";
import pca from "./images/pca-logo.svg";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

const Home = () => {
  return (
    <>
      <div className="home">
        <NavBar />
        <div className="home__lay"></div>
        <div className="home__wrp">
          <div className="container">
            <div className="home__contents">
              <div className="home__content__wrp">
                <h1 className="home__title">
                  <span className="yellow-text">Welcome</span> to <br /> Angry
                  Sheep Club
                </h1>
                <div className="home__subtitle">
                  <p>
                    Our hope is that we will become your #1 place for you to
                    grow, profit and truly thrive in life 💯
                  </p>
                </div>

                <div className="home__btn">
                  <a href="https://discord.gg/angrysheepclub" target="_blank">
                    <button>Join Us</button>{" "}
                  </a>
                </div>

                <div className="follow">
                  <p className="follow-text">Follow Us:</p>
                  <a href="https://twitter.com/AngrySheepClub" target="_blank">
                    <img src={twitter}></img>
                  </a>
                </div>
              </div>
              <div className="home__contents__card">
                <div className="home__card">
                  <img className="home__card__img" src={heroimage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="seen-on">
        <b>As Seen On:</b>
        <img src={AP} className="ap-image" />
        <img src={benzinga} className="benzinga-image" />
        <img src={marketwatch} className="marketwatch-image" />
        <img src={digitaljournal} className="dj-image" />
        <img src={yahoo} className="dj-image" />
        <img src={yahoonews} className="dj-image" />
        <img src={pca} className="dj-image" />
      </div>
      <Initiative />
      <Team />
      {/* <Blueprint /> */}
      <FAQ />
      <Footer />
    </>
  );
};

export default App;
