import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./NavBar.scss";
import Web3 from "web3";
import abi from "./abi.json";
import logo from "./../../images/newlogo.png";
import menubutton from "./../../images/menu.svg";
import Sidebar from "../SideBar/sidebar";
import Hamburger from "hamburger-react";
import xicon from "./../../images/x-icon.png";
import unrevealed from "./../../images/unrevealed.jpeg";
import comingsoon from "./../../images/comingsoons.png";
import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
import { PlatinumSeriesAddresses } from "./PlatinumSeriesAddresses.js";
import { first100Addresses } from "./first100Addresses";

import Modal from "react-modal";

function NavBar() {
  const [connectedAccount, setConnectedAccount] = useState(
    "CONNECT YOUR WALLET"
  );
  const [ismintmodalopen, setmintmodalopen] = useState(false);
  const [isclaimmodalopen, setclaimmodalopen] = useState(false);
  const [isaccountconnected, setaccountconnected] = useState(false);
  const [contract, setContract] = useState(null);
  const [first100contract, setfirst100Contract] = useState(null);

  const [TokensMinted, setTokenSupply] = useState(0);
  const [subpresale, setSubPresale] = useState(0);
  const [price, setPrice] = useState();
  const [priceInEth, setPriceInEth] = useState(0.08);
  const [quantity, setQuantity] = useState(1);
  const [minted, setMinted] = useState(false);
  const [isClose, setIsClose] = useState(false);
  const [serialNumber, setSerialNumber] = useState("");
  const [tokenId, setTokenId] = useState("");

  const leaf = PlatinumSeriesAddresses.map((addr) => keccak256(addr));
  const merkleTree = new MerkleTree(leaf, keccak256, { sortPairs: true });

  const leaf100 = first100Addresses.map(({ address, serialNumber }) =>
    keccak256((serialNumber, address))
  );
  const merkleTree100 = new MerkleTree(leaf100, keccak256, { sortPairs: true });
  useEffect(() => {
    loadWeb3();
  }, []);

  function checkWhitelist(a) {
    const check = keccak256(a);
    const proof = merkleTree.getHexProof(check);
    const root = merkleTree.getRoot();
    //This code paste in example 0xCode;
    console.log("Root Code to pase in contract: 0x", root.toString("hex"));
    return merkleTree.verify(proof, check, root);
  }

  function getProof(a) {
    const check = keccak256(a);
    return merkleTree.getHexProof(check);
  }

  function checkFirst100(a, s) {
    const check = keccak256([a, s]);
    const proof = merkleTree100.getHexProof(check);
    const root = merkleTree100.getRoot();
    //This code paste in example 0xCode;
    console.log(
      "Root Code to pase in contract for first100 addresses: 0x",
      root.toString("hex")
    );
    return merkleTree100.verify(proof, check, root);
  }

  function getProof100(a, s) {
    const check = keccak256([a, s]);
    return merkleTree100.getHexProof(check);
  }

  async function loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      // await window.ethereum.enable();
      const web3 = window.web3;
      // creating contract instance
      const contractaddress = "0x9ff9dA72AeeF400f11c8CE7733Edbf50b950d82F";
      const ct = new web3.eth.Contract(abi, contractaddress);
      setContract(ct);
      console.log("ct", ct);
      let price = await ct.methods.PRICE().call();
      console.log("price:", price);
      setPrice(price);
      setPriceInEth(web3.utils.fromWei(price, "ether"));
      const supply = await ct.methods.currentSupply().call();
      console.log(supply);
      setTokenSupply(supply);
      // const subPresaleAmount = await ct.methods.SubPresaleAmount().call();
      // setSubPresale(subPresaleAmount);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }

  async function connectWallet() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const web3 = window.web3;
      const metaMaskAccount = await web3.eth.getAccounts();
      console.log("wallet address: ", metaMaskAccount.toString());
      let splitedMetaMaskAddress;
      // getProof100(metaMaskAccount);

      // checkFirst100(metaMaskAccount);
      setaccountconnected(true);
      console.log("account is connected");
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }

  async function mint() {
    const web3 = window.web3;
    let price = await contract.methods.PRICE().call();
    setPrice(price);
    const _value = price * quantity;
    let address = await web3.eth.getAccounts();
    console.log("Address :", address);
    console.log("Value :", _value);
    address = address[0];
    let proof = getProof(address);
    if (checkWhitelist(address)) {
      await contract.methods
        .PresaleMint(quantity, proof)
        .send({ from: address, value: _value });
    } else {
      alert("You are Not white listed");
    }
  }

  async function claimReward(tokenid, serial) {
    window.web3 = new Web3(window.ethereum);
    // await window.ethereum.enable();
    const web3 = window.web3;
    // creating contract instance
    const contractaddress = "0x29012E3FEb21B19B0db3051481c435484298a03e";
    const ct = new web3.eth.Contract(abi, contractaddress);
    let address = await web3.eth.getAccounts();

    let proof = getProof100(address, serial);
    if (checkFirst100(address, serial)) {
      await contract.methods.claimReward(tokenId, proof).send({
        from: address,
      });
    }
  }

  function openMintModal() {
    setmintmodalopen(true);
  }

  function closeMintModal() {
    setmintmodalopen(false);
  }

  function openClaimModal() {
    setclaimmodalopen(true);
  }

  function closeClaimModal() {
    setclaimmodalopen(false);
  }

  return (
    <>
      <Modal
        isOpen={ismintmodalopen}
        onRequestClose={closeMintModal}
        className="MintModal"
        overlayClassName="overlay"
      >
        <img
          src={xicon}
          className="close-button-mint"
          onClick={closeMintModal}
        />
        {/* <div className="coming-soon"></div> */}
        <div className="mint-wrapper">
          <div className="mint-content">
            <h3 className="mint-heading">
              ANGRY SHEEP - PLATINUM SERIES <br /> 15001 NFTS
            </h3>
            <img src={unrevealed} className="mint-image" />
            <div className="mint-info">
              <div className="presale-div">
                <p>Presale</p>
                <p>#1/5</p>
              </div>
              <div className="presale-div">
                <p>Progression</p>
                <p>#{TokensMinted}/1999</p>
              </div>
              <div className="presale-div">
                <p>Minting</p>
                <div className="quantity-selector">
                  <button
                    type="button"
                    className="quantity-button border-right"
                    onClick={() => {
                      if (quantity > 1) {
                        setQuantity(quantity - 1);
                      }
                    }}
                  >
                    -
                  </button>
                  <button type="button" className="quantity-button ">
                    {quantity}/5
                  </button>
                  <button
                    type="button"
                    className="quantity-button border-left"
                    onClick={() => {
                      if (quantity < 5) {
                        setQuantity(quantity + 1);
                      }
                    }}
                  >
                    +
                  </button>
                </div>
                <p>NFT</p>
              </div>

              <button
                className="mint-button"
                onClick={async () => {
                  await mint();
                }}
              >
                MINT NOW!
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isclaimmodalopen}
        onRequestClose={closeClaimModal}
        className="MintModal"
        overlayClassName="overlay"
      >
        <img
          src={xicon}
          className="close-button-mint"
          onClick={closeClaimModal}
        />
        {/* <div className="coming-soon"></div> */}
        <div className="mint-wrapper">
          <div className="mint-content">
            <h3 className="mint-heading">
              CLAIM <br /> Reward
            </h3>
            <div className="mint-info">
              <form>
                <label>
                  Enter Your Serial Number:
                  <input
                    type="text"
                    value={serialNumber}
                    onChange={(e) => setSerialNumber(e.target.value)}
                  />
                </label>
                <label>
                  Enter Your Token ID:
                  <input
                    type="text"
                    value={tokenId}
                    onChange={(e) => setTokenId(e.target.value)}
                  />
                </label>
                <button onClick={() => claimReward(tokenId, serialNumber)}>
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      <div className="nav">
        {isClose && <Sidebar isClose={isClose} setIsClose={setIsClose} />}
        <div className="container">
          <div className="nav__wrp">
            <div className="nav__lft">
              <div className="nav__logo">
                <a href="/">
                  {" "}
                  <img src={logo} alt="" />{" "}
                </a>
              </div>
              <ul className="nav__links">
                <li className="nav__link">
                  <a href="/">Home</a>
                </li>
                <li className="nav__link">
                  <a href="#initiative">Initiative</a>
                </li>
                <li className="nav__link">
                  <a href="#team">Team</a>
                </li>
                <li className="nav__link">
                  <a href="#blueprint">Blueprint</a>
                </li>
                <li className="nav__link">
                  <a href="#faq">FAQ</a>
                </li>
              </ul>
            </div>
            <div className="nav__rgt">
              <div className="button-row">
                {!isaccountconnected ? (
                  <button
                    className="nav-button"
                    href=""
                    onClick={() => {
                      connectWallet();
                    }}
                  >
                    {connectedAccount}
                  </button>
                ) : (
                  <button
                    className="nav-button"
                    href=""
                    onClick={openMintModal}
                  >
                    Mint!
                  </button>
                )}
                {/* <button className="nav-button" href="" onClick={openClaimModal}>
                  Claim!
                </button> */}
              </div>
              <div className="nav__menu">
                <Hamburger
                  toggled={isClose}
                  toggle={setIsClose}
                  color="#ff9e00"
                />
              </div>
              {/* <button  onClick={() => setIsClose(true)}>
              <img src={menubutton} alt="" />
            </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavBar;
